var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('div',{staticStyle:{"margin":"5px 0px"}},[_c('el-form',{staticClass:"demo-form-inline",attrs:{"inline":true}},[_c('el-form-item',{attrs:{"label":"院校:"}},[_c('el-input',{staticClass:"form-line-item",attrs:{"placeholder":"请输入单位名称","size":"small"},model:{value:(_vm.search.institutionName),callback:function ($$v) {_vm.$set(_vm.search, "institutionName", $$v)},expression:"search.institutionName"}})],1),_c('el-form-item',{staticStyle:{"margin-left":"10px"}},[_c('el-button',{attrs:{"type":"primary","size":"mini","icon":"el-icon-search"},on:{"click":_vm.batchSearch}},[_vm._v(" 搜索 ")]),_c('el-button',{attrs:{"type":"success","size":"mini","icon":"el-icon-refresh"},on:{"click":_vm.resetHandle}},[_vm._v(" 重置 ")])],1)],1)],1),_c('div',[_c('vxe-table',{ref:"schoolTable",staticClass:"mytable-scrollbar college-table",attrs:{"header-cell-style":_vm.headerCellStyle,"round":"","align":"left","highlight-current-row":"","highlight-hover-row":"","keyboard-config":{isArrow: true},"checkbox-config":{trigger: 'cell', highlight: true, range: false},"loading":_vm.loading,"data":_vm.paperList}},[_c('vxe-column',{attrs:{"type":"seq","title":"序号","width":"100","show-overflow":'tooltip'}}),_c('vxe-column',{attrs:{"field":"institutionName","title":"院校","width":"300","show-overflow":'tooltip'},scopedSlots:_vm._u([{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('vxe-input',{attrs:{"type":"text"},model:{value:(row.institutionName),callback:function ($$v) {_vm.$set(row, "institutionName", $$v)},expression:"row.institutionName"}})]}}])}),_c('vxe-column',{attrs:{"field":"note","title":"等级","width":"250","show-overflow":'tooltip'}}),_c('vxe-column',{attrs:{"field":"type","title":"院校类型","width":"250","show-overflow":'tooltip'}}),_c('vxe-column',{attrs:{"field":"location","title":"所在地区","min-width":"300","show-overflow":'tooltip'}})],1),_c('vxe-pager',{attrs:{"perfect":"","align":"right","current-page":_vm.page.currentPage,"page-size":_vm.page.pageSize,"total":_vm.page.total,"page-sizes":[10, 20, 100, 1000, {label: '全量数据', value: _vm.page.total}],"layouts":['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total']},on:{"update:currentPage":function($event){return _vm.$set(_vm.page, "currentPage", $event)},"update:current-page":function($event){return _vm.$set(_vm.page, "currentPage", $event)},"update:pageSize":function($event){return _vm.$set(_vm.page, "pageSize", $event)},"update:page-size":function($event){return _vm.$set(_vm.page, "pageSize", $event)},"page-change":_vm.handlePageChange},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('vxe-button',{attrs:{"size":"small"},on:{"click":_vm.firstPage}},[_vm._v("首页 ")])]},proxy:true}])}),_c('vxe-modal',{attrs:{"title":_vm.isEdit ? '编辑&保存' : '新增&保存',"width":"800","min-width":"600","min-height":"300","loading":_vm.submitLoading,"resize":"","destroy-on-close":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('vxe-form',{attrs:{"data":_vm.formData,"rules":_vm.formRules,"title-align":"right","title-width":"100"},on:{"submit":_vm.submitEvent}},[_c('vxe-form-item',{attrs:{"field":"id","title":"院校代码","span":12,"item-render":{}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('vxe-input',{attrs:{"placeholder":"请输入院校代码"},model:{value:(data.id),callback:function ($$v) {_vm.$set(data, "id", $$v)},expression:"data.id"}})]}}])}),_c('vxe-form-item',{attrs:{"field":"name","title":"院校名称","span":12,"item-render":{}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('vxe-input',{attrs:{"placeholder":"请输入院校名称"},model:{value:(data.name),callback:function ($$v) {_vm.$set(data, "name", $$v)},expression:"data.name"}})]}}])}),_c('vxe-form-item',{attrs:{"field":"note","title":"院校等级","span":12,"item-render":{}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('vxe-input',{attrs:{"placeholder":"请输入密码"},model:{value:(data.note),callback:function ($$v) {_vm.$set(data, "note", $$v)},expression:"data.note"}})]}}])}),_c('vxe-form-item',{attrs:{"field":"department","title":"隶属部门","span":12,"item-render":{}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('vxe-input',{attrs:{"placeholder":"请输入隶属部门"},model:{value:(data.department),callback:function ($$v) {_vm.$set(data, "department", $$v)},expression:"data.department"}})]}}])}),_c('vxe-form-item',{attrs:{"field":"location","title":"所在地区","span":12,"item-render":{}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_c('vxe-input',{attrs:{"placeholder":"请输入所在地区"},model:{value:(data.location),callback:function ($$v) {_vm.$set(data, "location", $$v)},expression:"data.location"}})]}}])}),_c('vxe-form-item',{attrs:{"align":"center","title-align":"left","span":24},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('vxe-button',{attrs:{"type":"submit"},on:{"click":_vm.confirmInsert}},[_vm._v("提交")]),_c('vxe-button',{attrs:{"type":"reset"},on:{"click":_vm.resetInsert}},[_vm._v("重置")])]},proxy:true}])})],1)]},proxy:true}]),model:{value:(_vm.showEdit),callback:function ($$v) {_vm.showEdit=$$v},expression:"showEdit"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }