<template>
  <div class="app-container">
    <div style="margin:5px 0px;">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="院校:">
          <!-- <el-select v-model="search.universityId" size="medium" clearable filterable placeholder="请选择院校"
            class="form-line-item">
            <el-option v-for="(item,index) in universityList" :key='index' :label="item.universityName"
              :value="item.universityId"></el-option>
          </el-select> -->
          <el-input placeholder="请输入单位名称" v-model="search.institutionName" size='small' class="form-line-item">
          </el-input>
        </el-form-item>
        <el-form-item style="margin-left:10px;">
          <el-button type="primary" size="mini" icon="el-icon-search" @click="batchSearch">
            搜索
          </el-button>
          <el-button type="success" size="mini" icon="el-icon-refresh" @click="resetHandle">
            重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 表格 -->
    <div>
      <!-- <vxe-toolbar perfect style="background-color:#fff;padding:0px 10px;">
        <template #buttons>
          <el-button type="success" size="small" @click="singleInsert">单个导入</el-button>

          <el-upload style="display:inline-block;margin:0px 10px;" class="upload-demo" action="#"
            :on-change="handleChange" multiple :limit="1" :auto-upload="false" :show-file-list = 'false' :file-list="fileList">
            <el-button type="success" size="small">批量导入</el-button>
          </el-upload>

          <el-link type="primary" href='/static/院校.xlsx'>下载模板</el-link>
        </template>
      </vxe-toolbar> -->
      <!-- :loading="loading" -->
      <vxe-table :header-cell-style="headerCellStyle" round align="left" ref="schoolTable" highlight-current-row
        highlight-hover-row :keyboard-config="{isArrow: true}" class="mytable-scrollbar college-table"
        :checkbox-config="{trigger: 'cell', highlight: true, range: false}" :loading='loading' :data="paperList">
        <vxe-column type='seq' title="序号" width="100" :show-overflow="'tooltip'"></vxe-column>
        <!-- <vxe-column field="id" title="代码" width="10%" :show-overflow="'tooltip'">
          <template #edit="{ row }">
            <vxe-input v-model="row.id" type="text"></vxe-input>
          </template>
        </vxe-column> -->
        <vxe-column field="institutionName" title="院校" width="300" :show-overflow="'tooltip'">
          <template #edit="{ row }">
            <vxe-input v-model="row.institutionName" type="text"></vxe-input>
          </template>
        </vxe-column>
        <vxe-column field="note" title="等级" width="250" :show-overflow="'tooltip'">
        </vxe-column>
        <vxe-column field="type" title="院校类型" width="250" :show-overflow="'tooltip'">
        </vxe-column>

        <vxe-column field="location" title="所在地区" min-width="300" :show-overflow="'tooltip'">
        </vxe-column>
      </vxe-table>

      <vxe-pager perfect align='right' :current-page.sync="page.currentPage" :page-size.sync="page.pageSize"
        :total="page.total" :page-sizes="[10, 20, 100, 1000, {label: '全量数据', value: page.total}]"
        :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total']"
        @page-change="handlePageChange">
        <template #left>
          <vxe-button size="small" @click="firstPage">首页
          </vxe-button>
        </template>
      </vxe-pager>

      <vxe-modal v-model="showEdit" :title="isEdit ? '编辑&保存' : '新增&保存'" width="800" min-width="600" min-height="300"
        :loading="submitLoading" resize destroy-on-close>
        <template #default>
          <vxe-form :data="formData" :rules="formRules" title-align="right" title-width="100" @submit="submitEvent">
            <vxe-form-item field="id" title="院校代码" :span="12" :item-render="{}">
              <template #default="{ data }">
                <vxe-input v-model="data.id" placeholder="请输入院校代码"></vxe-input>
              </template>
            </vxe-form-item>
            <vxe-form-item field="name" title="院校名称" :span="12" :item-render="{}">
              <template #default="{ data }">
                <vxe-input v-model="data.name" placeholder="请输入院校名称"></vxe-input>
              </template>
            </vxe-form-item>
            <vxe-form-item field="note" title="院校等级" :span="12" :item-render="{}">
              <template #default="{ data }">
                <vxe-input v-model="data.note" placeholder="请输入密码"></vxe-input>
              </template>
            </vxe-form-item>
            <vxe-form-item field="department" title="隶属部门" :span="12" :item-render="{}">
              <template #default="{ data }">
                <vxe-input v-model="data.department" placeholder="请输入隶属部门"></vxe-input>
              </template>
            </vxe-form-item>
            <vxe-form-item field="location" title="所在地区" :span="12" :item-render="{}">
              <template #default="{ data }">
                <vxe-input v-model="data.location" placeholder="请输入所在地区"></vxe-input>
              </template>
            </vxe-form-item>
            <vxe-form-item align="center" title-align="left" :span="24">
              <template #default>
                <vxe-button type="submit" @click='confirmInsert'>提交</vxe-button>
                <vxe-button type="reset" @click='resetInsert'>重置</vxe-button>
              </template>
            </vxe-form-item>
          </vxe-form>
        </template>
      </vxe-modal>
    </div>
  </div>
</template>

<script>
  // import XLSX from 'xlsx'
  export default {
    name: 'collegeInfo',
    data() {
      return {
        loading: false,
        search: {
          institutionName: '',
        },
        universityList: [],
        //论文列表
        paperList: [{
          id: '12345',
          name: '南京理工大学',
          department: '工信部',
          location: '南京市',
          note: '211'
        }],
        fileList: [],
        formData: {
          id: '12345',
          name: '南京理工大学',
          department: '工信部',
          location: '南京市',
          note: '211'
        },
        //分页
        page: {
          currentPage: 1,
          pageSize: 10,
          pageCount: 40, //自己用
          pageSizes: [1, 5, 10, 15, 20],
          total: 0
        },
        //弹出框
        showEdit: false,
        isEdit: true,
        submitLoading: false
      }
    },
    created() {
        window.addEventListener('keydown', this.handleKeyPress);
      this.batchSearch();
      this.getUniversityList();
    },
    methods: {
        handleKeyPress(event) {
            if (event.keyCode === 13) {
                this.batchSearch();
            }
        },

      //获取院校列表
      getUniversityList() {
        this.$axios.get("/school/get_university")
          .then(res => {
            this.universityList = res.data.data;
          })
          .catch(err => {
            this.$message.warning("服务器维护");
          });
      },
      //获取论文列表
      getPaperList() {
        this.loading = true;
        let params = {
            "institutionName": this.search.institutionName,
            "note":[],
            "type": "",
            "location": "",
            "isDisplay": "",
            "levels": "",
            "pageSize": this.page.pageSize,
            "pageNum": this.page.currentPage
          }

        this.$api.basicInfo.getInstitutionInfo(params)
        .then(res=>{
          this.paperList = res.data.data;
          this.page.total = res.data.count;
          this.loading = false;
        })
      },
      //搜索
      batchSearch() {
        this.getPaperList()
      },
      resetHandle() {
        this.search = {
          university: '',
        };
      },
      //批量添加账号
      handleChange(file, fileList) {
        const loading = this.$loading({
          lock: true,
          text: '上传中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        console.log(file);
        var reader = new FileReader(); //读取文件内容
        reader.readAsArrayBuffer(file.raw); //防止中文乱码问题，不加reader.onload方法都不会触发
        reader.onload = (e) => {
          var dd = e.target.result;
          console.log(dd)
          var workbook = XLSX.read(dd, {
            type: 'buffer'
          });
          var sheetname = workbook.SheetNames[0]
          var tables = XLSX.utils.sheet_to_json(workbook.Sheets[sheetname])
          console.log(tables);
          var colleges = [];
          for (let i in tables) {
            let row = tables[i];
            var paps = {
              id: row['代码'],
              name: row['院校'],
              department: row['隶属部门'],
              location: row['所在地区'],
              note: row['等级'] == 'undefined' ? '' : row['等级']
            };
            colleges.push(paps);
          };

          //发送请求
          let param = {
            universityList: colleges
          };
          this.$axios.post('/university/add_university', param)
            .then(res => {
              if (res.data == 1) {
                this.$message.success("添加成功！");
                this.batchSearch();
                this.showEdit = false;
              } else {
                this.$message.warning("添加失败！");
              }
              loading.close();
            }).catch(err => {
              this.$message.warning("服务器维护！");
              loading.close();
            })
        }
      },
      //分页操作
      handlePageChange({
        currentPage,
        pageSize
      }) {
        this.page.currentPage = currentPage;
        this.page.pageSize = pageSize;
        this.getPaperList();
      },
      //每页多少数据改变
      handleChangePageSize(val) {
        this.page.pageSize = val;
        this.page.currentPage = 1;
        this.getPaperList();
      },
      firstPage() {
        this.page.currentPage = 1;
        this.getPaperList();
      },
      endPage() {
        this.page.currentPage = parseInt(this.page.total / this.page.pageSize) + (this.page.total % this.page
          .pageSize == 0 ? 0 : 1);
        this.getPaperList();
      },
      headerCellStyle({
        row,
        column,
        rowIndex,
        columnIndex
      }) {
        return "background-color:#e0e0e0f1"
      },
    },
    components: {

    }
  }
</script>

<style scoped>

</style>

<style>
  .college-table .vxe-table--body-wrapper {
    height: calc(100vh - 260px);
  }
</style>